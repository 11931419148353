module services {
    export module applicationcore {
        export class countryService implements interfaces.applicationcore.ICountryService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Country/GetDropdownList", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            get(): ng.resource.IResourceClass<interfaces.applicationcore.ICountry> {

                return this.$resource<interfaces.applicationcore.ICountry>(this.ENV.DSP_URL + "Country/Get", {
                    cntId: "@cntId"
                });
            }

            GetCurrentCountry(): ng.resource.IResourceClass<interfaces.applicationcore.ICountry> {
                return this.$resource<interfaces.applicationcore.ICountry>(this.ENV.DSP_URL + "Country/GetCurrentCountry", {
                });
            }
        }
    }
    angular.module("app").service("countryService", services.applicationcore.countryService);
}